<template>
    <section>
        <div class="bg-white br-12 py-3 px-2">
            <div class="row mx-0">
                <div class="col">
                    <p class="f-18 text-general f-600">Envíos en las ultimas 24 semanas</p>
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div class="col-12">
                    <echart :options="envios_24_semanas" />
                </div>
            </div>
        </div>
        <div class="bg-white br-12 py-3 my-2 px-2">
            <div class="row mx-0">
                <div class="col">
                    <p class="f-18 text-general f-600">Campañas con mayor número de envíos y mayor alcance en las ultimas 24 semanas</p>
                </div>
                <div class="col-auto">
                    <div class="d-flex br-4 border-black" style="width:64px;">
                        <div :class="`w-50 cr-pointer text-center br-l-4 ${campanasTipo == 1 ? 'filter-active' : 'text-general bg-white'}`" @click="campanasTipo = 1">
                            <i class="icon-enviar" />
                        </div>
                        <div :class="`w-50 cr-pointer text-center ${campanasTipo == 2 ? 'filter-active' : 'text-general bg-white'}`" @click="campanasTipo = 2">
                            <i class="icon-percent" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-2">
                <div class="col-12">
                    <echart :options="campanas_mayor" />
                </div>
            </div>
        </div>
        <div class="bg-white br-12 py-3 my-2 px-2">
            <div class="row mx-0">
                <div class="col">
                    <p class="f-18 text-general f-600">CEDIS con mayor número de envíos y mayor alcance en las ultimas 24 semanas</p>
                </div>
                <div class="col-auto">
                    <div class="d-flex br-4 border-black" style="width:64px;">
                        <div :class="`w-50 cr-pointer text-center ${cedisTipo == 1 ? 'filter-active' : 'text-general bg-white'}`" @click="cedisTipo = 1">
                            <i class="icon-enviar" />
                        </div>
                        <div :class="`w-50 cr-pointer text-center ${cedisTipo == 2 ? 'filter-active' : 'text-general bg-white'}`" @click="cedisTipo = 2">
                            <i class="icon-percent" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-2">
                <div class="col-12">
                    <echart :options="cedis_mayor" />
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-2">
            <div class="col bg-white br-12 px-0 mr-1">
                <p class="text-center text-general f-18 f-600">
                    Lanzamientos en los últ. 6 meses
                </p>
                <div class="row mx-0 mt-2">
                    <div class="col text-center enviados">
                        <p class="text-general text-center f-24 f-600">{{ lanzamientos.lanzado }}</p>
                        <p class=" text-green">
                            <i class="icon-ok-circled-outline f-18" />
                            Enviados
                        </p>
                    </div>
                    <div class="col text-center text-general-red">
                        <p class="text-general text-center f-24 f-600">{{ lanzamientos.cancelado }}</p>
                        <p class=" text-green">
                            <i class="icon-cancel-outline f-18" />
                            Cancelados
                        </p>
                    </div>
                </div>
            </div>
            <div class="col bg-white br-12 px-0 ml-1">
                <p class="text-center text-general f-18 f-600">
                    Lanzamientos pendientes
                </p>
                <div class="row mx-0 mt-2">
                    <div class="col text-center text-general cr-pointer" @click="verLanzamientos(2)">
                        <p class="text-general text-center f-24 f-600">{{ lanzamientos.por_lanzar }}</p>
                        <p class=""> <i class="icon-clock f-18" /> Programados</p>
                    </div>
                    <div class="col text-center pausados cr-pointer" @click="verLanzamientos(1)">
                        <p class="text-general text-center f-24 f-600">{{ lanzamientos.creado }}</p>
                        <p class=""> <i class="icon-pause-circle-outline f-20" /> Pausados</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-lanzamientos ref="modalLanzamientos" />
    </section>
</template>

<script>

let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
let colorGeneral3 = getComputedStyle(document.documentElement) .getPropertyValue('--color-general2')

import { mapGetters, mapMutations } from 'vuex'
import notificaciones from '~/services/publicidad/notificaciones'
export default {
    components: {
        modalLanzamientos: () => import('./partials/modalLanzamientos')
    },
    data(){
        return {
            campanasTipo: 1,
            cedisTipo: 1,
        }
    },
    computed: {
        ...mapGetters({
            graficaModel: 'publicidad/notificaciones/graficaModel',
            graficaEnvios: 'publicidad/notificaciones/graficaEnvios',
            graficaCampanas: 'publicidad/notificaciones/graficaCampanas',
            graficaCedis: 'publicidad/notificaciones/graficaCedis',
            lanzamientos: 'publicidad/notificaciones/lanzamientos',
        }),
        envios_24_semanas(){
            return {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: true
                        }
                    }
                },
                legend: {
                    data: ["Envíos PUSH", "Enviós sms", "alcance"],
                    top: "4%",
                    textStyle: {
                        color: "#6D6B6B",
                        fontSize: 14
                    }
                },
                xAxis: {
                    data: this.graficaEnvios.semanas,
                    axisLine: {
                        show: false, //隐藏X轴轴线
                        lineStyle: {
                            color: "#3d5269",
                            width: 1
                        }
                    },
                    axisTick: {
                        show: false, //隐藏X轴刻度
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: "#396A87", //X轴文字颜色
                            fontSize: 14
                        },
                        interval: 0,
                        rotate: 30
                    }
                },
                yAxis: [{
                    type: "value",
                    nameTextStyle: {
                        color: "#396A87",
                        fontSize: 14
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            width: 1,
                            color: "#3d5269"
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6D6B6B",
                            fontSize: 14
                        }
                    }
                },
                {
                    type: "value",
                    nameTextStyle: {
                        color: "#396A87",
                        fontSize: 14
                    },
                    position: "right",
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#396A87",
                            width: 2
                        }
                    },
                    axisLabel: {
                        show: true,
                        formatter: "{value} %", //右侧Y轴文字显示
                        textStyle: {
                            color: "#6D6B6B",
                            fontSize: 14
                        }
                    }
                }
                ],
                series: [{
                    name: "Envíos PUSH",
                    type: "bar",
                    stack: '余额',
                    barWidth: 18,
                    itemStyle: {
                        normal: {
                            color: colorGeneral3
                        }
                    },
                    data: this.graficaEnvios.push
                },
                {
                    name: "Enviós sms",
                    type: "bar",
                    stack: '余额',
                    barWidth: 18,
                    itemStyle: {
                        normal: {
                            color: colorGeneral2
                        }
                    },
                    data: this.graficaEnvios.mensaje
                },
                {
                    name: "alcance",
                    type: "line",
                    yAxisIndex: 1,
                    showAllSymbol: true,
                    symbol: "circle",
                    symbolSize: 6,
                    itemStyle: {
                        color: colorGeneral,
                        borderColor: colorGeneral,
                        width: 2,
                    },
                    lineStyle: {
                        color: colorGeneral,
                        width: 2,
                        shadowBlur: 2
                    },
                    data: this.graficaEnvios.apertura
                }
                ]
            }
        },
        campanas_mayor(){
            let series = []
            if (this.campanasTipo == 1){
                series = [
                    {
                        name: 'Envíos Push',
                        type: 'bar',
                        stack: '余额',
                        barMaxWidth: 30,
                        data: this.graficaCampanas.push,
                        itemStyle: {
                            normal: {
                                color: colorGeneral3,
                            },
                        },
                    },
                    {
                        name: 'Envios sms',
                        type: 'bar',
                        stack: '余额',
                        barMaxWidth: 30,
                        data: this.graficaCampanas.mensaje,
                        itemStyle: {
                            normal: {
                                color: colorGeneral2,
                            },
                        },
                    }
                ]
                
            } else {
                series = [
                    {
                        name: 'Alcance',
                        type: 'bar',
                        stack: '余额',
                        barMaxWidth: 30,
                        data: this.graficaCampanas.apertura,
                        itemStyle: {
                            normal: {
                                color: colorGeneral3,
                            },
                        },
                        
                    }
                ]
            }
            return  {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: true,
                        }
                    }
                },
                legend: {
                    textStyle: {
                        color: '#707070',
                    },
                    data: ['Envíos Push', 'Envios sms']
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 1
                        }
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: "#6D6B6B",
                            fontSize: 14
                        }
                    },  
                    data: this.graficaCampanas.nombres,
                },
                yAxis: {
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 1
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#AAAAAA56",
                        }
                    },
                    axisLabel: {
                        show: true,
                        formatter: `{value} ${this.campanasTipo == 2 ? '%': ''}`, //右侧Y轴文字显示
                        textStyle: {
                            color: "#6D6B6B",
                            fontSize: 14
                        }
                    }
                },
                series
            }
        },
        cedis_mayor(){
            let series = []
            if (this.cedisTipo == 1){
                series = [
                    {
                        name: 'Envíos Push',
                        type: 'bar',
                        stack: '余额',
                        barMaxWidth: 30,
                        data: this.graficaCedis.push,
                        itemStyle: {
                            normal: {
                                color: colorGeneral3,
                            },
                        },
                    },
                    {
                        name: 'Envios sms',
                        type: 'bar',
                        stack: '余额',
                        barMaxWidth: 30,
                        data: this.graficaCedis.mensaje,
                        itemStyle: {
                            normal: {
                                color: colorGeneral2,
                            },
                        },
                    }
                ]
                
            } else {
                series = [
                    {
                        name: 'Alcance',
                        type: 'bar',
                        stack: '余额',
                        barMaxWidth: 30,
                        data: this.graficaCedis.apertura,
                        itemStyle: {
                            normal: {
                                color: colorGeneral3,
                            },
                        },
                        
                    }
                ]
            }
            return {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: true
                        }
                    }
                },
                legend: {
                    textStyle: {
                        color: '#707070',
                    },
                    data: ['Envíos Push', 'Envios sms']
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 1
                        }
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: "#6D6B6B",
                            fontSize: 14
                        }
                    },  
                    data: this.graficaCedis.nombres
                },
                yAxis: {
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 1
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#AAAAAA56",
                        }
                    },
                    axisLabel: {
                        show: true,
                        formatter: `{value} ${this.cedisTipo == 2 ? '%': ''}`, //右侧Y轴文字显示
                        textStyle: {
                            color: "#6D6B6B",
                            fontSize: 14
                        }
                    }
                },
                series
            }
        }
    },
    watch: {
        campanasTipo(val){
            this.cambiarGraficaCampanas()
        },
        cedisTipo(val){
            this.cambiarGraficaCedis()
        },
    },
    methods: {
        ...mapMutations({
            setGraficaCampanas: 'publicidad/notificaciones/setGraficaCampanas',
            setGraficaCedis: 'publicidad/notificaciones/setGraficaCedis',
        }),
        async cambiarGraficaCampanas(){
            try {
                let params = {
                    carpeta: this.graficaModel.carpeta,
                    campana: this.graficaModel.campana,
                    tipo: this.graficaModel.tipo,
                    tipo_lanzamiento: this.campanasTipo
                }
                const { data } = await notificaciones.cambiarGraficaCampanas(params);
                this.setGraficaCampanas(data)
            } catch (error){
                this.error_catch(error)
            }
        },
        async cambiarGraficaCedis(){
            try {
                let params = {
                    carpeta: this.graficaModel.carpeta,
                    campana: this.graficaModel.campana,
                    tipo: this.graficaModel.tipo,
                    tipo_lanzamiento: this.cedisTipo
                }
                const { data } = await notificaciones.cambiarGraficaCedis(params);
                this.setGraficaCedis(data)
            } catch (error){
                this.error_catch(error)
            }
        },
        verLanzamientos(type){
            this.$refs.modalLanzamientos.toggle(type);
        }
    }
}
</script>
<style lang="scss" scoped>
.filter-active{
    background-color: var(--text-general) !important;
    color: #ffffff;
}
.enviados{
    color: #27D07B;
}
.pausados{
    color: #2196F3;
}
</style>